import React from 'react';
import MainPhoto from '../public_assets/online-platform-section-photo.png';

function onlinePlatformSection(props) {
    return (
        <div id='online-platform-section'>
            <div className="container-fluid d-flex justify-content-center" style={{width:"80%"}}>
                <div className="row">
                    <div className="col d-flex justify-content-center">
                        <div className='card bg-transparent border-0 mt-2'>
                            <div className="card-body text-center">
                                <h2 className="card-title">
                                    Accessible Online Platform
                                </h2>
                                <p className='card-text text-secondary'>
                                    The exclusive online platform allows accessible meets, regardless of time and location. Our tutors, meeting, and consultations are carried out online and offline, allowing flexbility.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-8 d-flex align-items-center justify-content-center">
                        <img className='card-img-right rounded img-fluid' src={MainPhoto} alt='Placeholder'></img>
                    </div>
                </div>
            </div>
            {/* <div className="container-fluid d-flex justify-content-center" style={{width:"80%"}}>
                <div className="row">
                    <div className="col-4 offset-1">
                        <div className='row mt-5 mx-auto text-center'>
                            <h2>Accessible Online Platform</h2>
                        </div>
                        <div className='row mx-auto text-center'>
                            <p className='text-secondary'>
                                The exclusive online platform allows accessible meets, regardless of time and location. Our tutors, meeting, and consultations are carried out online and offline, allowing flexbility.
                            </p>
                        </div>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-center">
                        <img className='rounded img-fluid' src={MainPhoto} alt='Placeholder'></img>
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default onlinePlatformSection;