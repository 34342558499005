import firebase from 'firebase/app';
import 'firebase/functions';

const config = {
    apiKey: "AIzaSyDfDUvbmLmBdyCAls-HRDsVvbWsPPen7p8",
    authDomain: "merica-university.firebaseapp.com",
    databaseURL: "https://merica-university.firebaseio.com",
    projectId: "merica-university",
    storageBucket: "merica-university.appspot.com",
    messagingSenderId: "393013661515",
    appId: "1:393013661515:web:c434cfe012abfc79f062ce",
    measurementId: "G-JVSXWSP3ME"
};

class Firebase {
    constructor() {
        var app = firebase.initializeApp(config);

        this.sendContactEmail = firebase.functions().httpsCallable('sendContactEmail');

        console.log(`Initialized Firebase`)
    }
}

export default Firebase;