import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Brochure from '../public_assets/ELE-Brochure.png';

function ElementaryModal(props) {

  return (
    <Modal
      {...props}
      dialogClassName="ElementaryModal"
      aria-labelledby="elementary-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="elementary-modal">
          Elementary Education
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img src={Brochure} alt="Elementary Education Brochure" width="100%" />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ElementaryModal;