import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import PlanPhoto from '../public_assets/plan-steps.png';
import OutlinePhoto from '../public_assets/outline.png';
import FollowUpPhoto from '../public_assets/follow-up.png';

function RequestService(props) {
    return (
        <Container id="request-service">
            <Row className="justify-content-center mb-3">
                <div>
                    <h2 style={{fontWeight:"bold"}}>
                        How Merica Works
                    </h2>
                </div>
            </Row>
            <Row>
                <Col className="my-2">
                    <Card className="border-0 bg-transparent">
                        <div className="mx-auto"><Card.Img className="w-auto" src={PlanPhoto} alt='Planning'></Card.Img></div>
                        <Card.Body className="text-center">
                            <Card.Title>
                                <h3>Plan</h3>
                            </Card.Title>
                            <p className="card-text">
                                Merica administers strategic planning, assessing both where you are and where you're going. You share your vision and values, together we plan to reach them.
                            </p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="my-2">
                    <Card className="border-0 bg-transparent">
                        <div className="mx-auto"><Card.Img className="w-auto" src={OutlinePhoto} alt='Placeholder'></Card.Img></div>
                        <Card.Body className="text-center">
                            <Card.Title>
                                <h3>Guidance</h3>
                            </Card.Title>
                            <p className="card-text">
                                Merica  outlines your academic curriculum for you, guiding you to your ultimate goal.
                            </p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="my-2">
                    <Card className="border-0 bg-transparent">
                        <div className="mx-auto"><Card.Img className="w-auto" src={FollowUpPhoto} alt='Placeholder'></Card.Img></div>
                        <Card.Body className="text-center">
                            <Card.Title>
                                <h3>Follow Up</h3>
                            </Card.Title>
                            <p className="card-text">
                                Merica believes in individual follow up, ensuring quality, communication, and growth.
                            </p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default RequestService;