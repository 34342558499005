import React from 'react';
import {Button} from 'react-bootstrap';
import ElementaryModal from '../modals/elementary';
import JhsModal from '../modals/jhs';
import HsModal from '../modals/hs';
import ConsultingModal from '../modals/consulting';
import ContactUsModal from '../modals/contact-us';
import CovidCrisisModal from '../modals/covid-crisis';
import Logo from '../public_assets/logo-labeled-black.png';
import LogoWhite from '../public_assets/logo-labeled-white.png';


function MyNav() {
    const [elementaryShow, setElementaryShow] = React.useState(false);
    const [jhsShow, setJhsShow] = React.useState(false);
    const [hsShow, setHsShow] = React.useState(false);
    const [consultingShow, setConsultingShow] = React.useState(false);
    const [contactShow, setContactShow] = React.useState(false);
    const [scroll, setScroll] = React.useState(false);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [crisisShow, setCrisisShow] = React.useState(false);
    
    let navStyle;
    if(scroll===false && menuOpen===false){navStyle="navbar navbar-expand-lg fixed-top bg-transparent";}
    if(scroll===false && menuOpen===true){navStyle="navbar navbar-expand-lg fixed-top bg-tiffany";}
    if(scroll===true){navStyle="navbar navbar-expand-lg fixed-top bg-navBG";}

    React.useEffect(() => {
        // Show crisis modal after delay
        const timer = setTimeout(() => setCrisisShow(true), 2500);
        return () => clearTimeout(timer);
    }, []);

    React.useEffect(() => {
        // Change navbar theme on scroll
        document.addEventListener("scroll", () => {
            const scrollCheck = window.scrollY > 100;
            if (scrollCheck !== scroll) {
                setScroll(scrollCheck)
            }
        });
    });

    return (
        <div>
            <nav className={navStyle}
                  style={{ transition: "0.5s ease" }}
            >
                <a className="navbar-brand pl-4" href="/">
                    <img src={scroll ? Logo : LogoWhite} alt='Company Logo' />
                </a>
                <button id="menu"
                        className={scroll ? 
                                "navbar-toggler custom-toggler-blue" : 
                                "navbar-toggler custom-toggler-white"
                                } 
                        onClick={() => setMenuOpen(!menuOpen)}
                        style={{outline:"none",border:"none"}} 
                        type="button" 
                        data-toggle="collapse" 
                        data-target="#navbarNav" 
                        aria-controls="navbarNav" 
                        aria-expanded="false" 
                        aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className={scroll ? "navbar-nav mr-auto nav-blue mx-5" : "navbar-nav mr-auto nav-white mx-5"}>
                        <li className="nav-item text-center" id='about-link'>
                            <a className="nav-link" href={menuOpen ? "#About-Menu" : "#About"}>ABOUT</a>
                        </li>
                        <li className="nav-item text-center">
                            <div className="nav-link" href="" onClick={() => setElementaryShow(true)}>ELEMENTARY</div>
                        </li>
                        <li className="nav-item text-center">
                            <div className="nav-link" href="" onClick={() => setJhsShow(true)}>JUNIOR HIGH SCHOOL</div>
                        </li>
                        <li className="nav-item text-center">
                            <div className="nav-link" href="" onClick={() => setHsShow(true)}>HIGH SCHOOL</div>
                        </li>
                        <li className="nav-item text-center">
                            <div className="nav-link" href="" onClick={() => setConsultingShow(true)}>CONSULTING</div>
                        </li>
                    </ul>
                    <div className="mx-2 pl-5 text-center">
                        <Button size="md" 
                                variant={scroll ? "outline-primary" : "outline-light"} 
                                onClick={(e) => {
                                    setContactShow(true);
                                    e.target.blur();
                                }}
                        >
                            FREE MOCK TEST
                        </Button>
                    </div>
                </div>
            </nav>

            <ElementaryModal
                show={elementaryShow}
                onHide={() => setElementaryShow(false)}
            />
            <JhsModal
                show={jhsShow}
                onHide={() => setJhsShow(false)}
            />
            <HsModal
                show={hsShow}
                onHide={() => setHsShow(false)}
            />
            <ConsultingModal
                show={consultingShow}
                onHide={() => setConsultingShow(false)}
            />
            <ContactUsModal
                show={contactShow}
                onHide={() => setContactShow(false)}
            />
            <CovidCrisisModal
                show={crisisShow}
                onHide={() => setCrisisShow(false)}
            />

        </div>
    );
}

export default MyNav;