import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import SubmitNotice from './submit-notice';
import { FirebaseContext } from '../Firebase'

function ContactUs(props) {

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [submitNotice, setSubmitNotice] = React.useState(false);

  return (
    <div>
      <Modal
        {...props}
        dialogClassName="ContactUs"
        aria-labelledby="contact-us"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contact-us">
            Contact Us
                    </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <FirebaseContext.Consumer>
            {firebase => (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  props.onHide();
                  setSubmitNotice(true);
                  const inquiryForm = {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phone: phone,
                    message: message
                  };
                  firebase.sendContactEmail(inquiryForm).then((res) => {
                    if (res.responseCode === 200) {
                      console.log('Inquiry sent')
                    }
                    else {
                      console.log('Error in sending')
                    }
                  });
                }}
              >
                <Form.Row>
                  <Form.Group as={Col} md="6" controlId="formFirstName">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="First name"
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="formLastName">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Last name"
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>

                <Form.Group controlId="formGridEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    placeholder="example@example.com"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formPhone">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    required
                    type="tel"
                    placeholder="(xxx)-xxx-xxxx"
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                <div className="form-group">
                  <label htmlFor="messageForm">Message:</label>
                  <textarea
                    required
                    className="form-control"
                    id="messageForm"
                    rows="3"
                    placeholder="What information would you like to know?"
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}>
                  </textarea>
                </div>

                <Button
                  variant="primary"
                  type="submit"
                >
                  Submit
                                </Button>
              </Form>)}

          </FirebaseContext.Consumer>

        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <SubmitNotice
        show={submitNotice}
        onHide={() => setSubmitNotice(false)}
      />
    </div>
  );
}

export default ContactUs;