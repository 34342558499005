import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Brochure from '../public_assets/HS-Brochure.png';

function HsModal(props) {
  return (
    <Modal
      {...props}
      dialogClassName="HsModal"
      aria-labelledby="hs-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="hs-modal">
          High School Education
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img src={Brochure} alt="High School Education Brochure" width="100%" />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default HsModal;