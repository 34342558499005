import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function CovidCrisis(props) {
  return (
    <Modal
    {...props}
    dialogClassName="CovidCrisisModal"
    aria-labelledby="covid-crisis-modal"
    centered
    >
    <Modal.Header closeButton>
        <Modal.Title className="w-100" id="covid-crisis-modal">
        <h5 className='w-100 mt-1 text-center'><strong>
            Coronavirus Update: 
            <br />
            Merica Consulting’s Response to COVID-19</strong></h5>
        </Modal.Title>
    </Modal.Header>
    <Modal.Body className="mx-auto">
        <p>Merica Consulting is closely monitoring the ongoing COVID-19 pandemic.</p>
        <span>
        <ul>
            <li>
                <span style={{color:"red"}}>LIVE </span> 
                On-Line classes available (US, Korea, and beyond)
                </li>
            <li>1 hour Free academic advancement guidance</li>
            <li>On-Line Summer Research Program for high school students</li>
            <li>On-Going College Essay Guidance</li>
        </ul>
        </span>
    </Modal.Body>
    <Modal.Footer>
        <Button variant="outline-secondary" size="sm" onClick={props.onHide}>Close</Button>
    </Modal.Footer>
    </Modal>
  );
}


export default CovidCrisis;