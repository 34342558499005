import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Brochure from '../public_assets/JHS-Brochure.png';

function JhsModal(props) {

  return (
    <Modal
      {...props}
      dialogClassName="JhsModal"
      aria-labelledby="jhs-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="jhs-modal">
          Junior High School Education
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img src={Brochure} alt="Junior High School Education Brochure" width="100%" />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default JhsModal;