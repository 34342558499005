import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ConsultingLocal from '../public_assets/college-consulting.png'
import ConsultingIntl from '../public_assets/college-consulting-intl.png'

function Consulting(props) {
  return (
    <Modal
      {...props}
      dialogClassName="ConsultingModal"
      aria-labelledby="consulting-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="consulting-modal">
          Consulting Services
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img src={ConsultingLocal} alt="College Consulting" width="100%" />
        <img src={ConsultingIntl} alt="College Consulting - International" width="100%" />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Consulting;