import React from 'react';
import { Container, Col, Row, Media } from 'react-bootstrap';
import Logo from '../public_assets/logo-white.png';

function contactFooter(props) {
    return (
        <Container fluid>
            <Row className='footer_main mt-2'>
                <Col lg={1} className='d-none d-lg-block'>
                </Col>
                <Col sm={1} lg={4} className='justify-content-center my-auto'>
                    <Row>
                        <Media className='mx-auto'>
                            <img src={Logo} alt='Company Logo'></img>
                            <h3 className='my-auto text-white' style={{ fontFamily: 'Times New Roman' }} >MericaConsulting, Inc</h3>
                        </Media>
                    </Row>
                </Col>
                <Col lg={2} className='d-none d-lg-block'>
                </Col>
                <Col lg={4} className='justify-content-center text-white my-auto'>
                    <p style={{ fontFamily: 'Times New Roman' }} className='my-auto text-center'>
                        © Copyright 2020. All Rights Reserved.
                    </p>
                </Col>
                <Col lg={1} className='d-none d-lg-block'>
                </Col>
            </Row>
        </Container >
    );
}

export default contactFooter;