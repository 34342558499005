import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import OutsideClickHandler from 'react-outside-click-handler';

import {
    activateSearch,
    deactivateSearch
} from '../actions/searchAction';
import SearchMenu from './searchMenu';


function SearchBar(props) {
    return (
        <Container>
            <div className='mx-auto h-100 w-75'>
                <OutsideClickHandler onOutsideClick={() => props.deactivateSearch()}>
                    <div className='input-group'>
                        <input type="text"
                            className='form-control'
                            placeholder="What information would you like to know?"
                            size="lg"
                            onClick={(e) => {
                                props.activateSearch();
                                e.target.blur();
                            }} 
                        />
                        <div className='input-group-append'>
                            <Button onClick={(e) => {
                                        e.preventDefault();
                                        e.target.blur();
                                    }}
                                    aria-label='Search'
                            >
                                Search
                            </Button>
                        </div>
                    </div >
                    {props.searchActive ? <SearchMenu /> : null}
                </OutsideClickHandler>
            </div>
        </Container>
    );
}

function msp(state) {
    return {
        searchActive: state.search.searchActive
    };
}

export default connect(msp, {
    activateSearch,
    deactivateSearch
})(SearchBar);