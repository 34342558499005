const defaultState = {
    searchActive: false
};

function searchReducer (state = defaultState, action) {
    switch (action.type) {
        case 'ACTIVATE SEARCH':
            return {...state, searchActive: true}
        case 'DEACTIVATE SEARCH':
            return {...state, searchActive: false}
        default:
            return state;
    }
};

export default searchReducer;