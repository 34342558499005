import React from 'react';
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import { FirebaseContext } from '../Firebase';
import SubmitNotice from '../modals/submit-notice';
import LocationIcon from '../public_assets/location-icon.png';
import PhoneIcon from '../public_assets/phone-icon.png';

function ContactUsSection(props) {
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [submitNotice, setSubmitNotice] = React.useState(false);

    return (
        <Container>
            <Row className='justify-content-center mb-2'>
                <h2>Contact Us</h2>
            </Row>
            <Row>
                <Col sm={8}>
                    <FirebaseContext.Consumer>
                        {firebase =>
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                setSubmitNotice(true);

                                var nameSections = name.trim().split(" ");
                                var firstName = 'Default First Name';
                                var lastName = 'Default Last Name';
                                if (nameSections.length === 1) {
                                    firstName = "First Name Not Found";
                                    lastName = nameSections[0];
                                }
                                else {
                                    firstName = nameSections.slice(0, -1).join(" ");
                                    lastName = nameSections[nameSections.length - 1];
                                }

                                const inquiryForm = {
                                    firstName: firstName,
                                    lastName: lastName,
                                    email: email,
                                    phone: phone,
                                    message: message
                                };
                                firebase.sendContactEmail(inquiryForm).then((res) => {
                                    if (res.responseCode === 200) {
                                        console.log('Inquiry sent')
                                    }
                                    else {
                                        console.log('Error in sending')
                                    }
                                });
                            }}>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Control required
                                                type='text'
                                                placeholder='Name'
                                                value={name}
                                                onChange={(e) => { setName(e.target.value) }} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Control required
                                                type='email'
                                                placeholder='Email'
                                                onChange={(e) => setEmail(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Control
                                                type='phone'
                                                placeholder='Phone'
                                                onChange={(e) => setPhone(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Control as='textarea'
                                                rows='10'
                                                placeholder='Message'
                                                onChange={(e) => setMessage(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button className='btn-primary'
                                            type='submit'>
                                            Send Now
                                            </Button>
                                    </Col>
                                </Row>
                            </Form>
                        }
                    </FirebaseContext.Consumer>
                </Col>
                <Col md={4}>
                    <Row className='mb-2'>
                        <Col>
                            <h3>Contact Details</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6>Flushing Location:</h6>
                            <Row className='pl-3'>
                                <img src={LocationIcon} alt='Location icon' className='h-100 pt-1 mr-1'></img>
                                <p className='text-muted'><small>42-02 192nd Street<br />
                                    Flushing, NY 11358</small></p>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6>Manhasset Location:</h6>
                            <Row className='pl-3'>
                                <img src={LocationIcon} alt='Location icon' className='h-100 pt-1 mr-1'></img>
                                <p className='text-muted'><small>1129 Northern Blvd. Suite 404<br />
                                    Manhasset, NY 11030</small></p>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6>Phone:</h6>
                            <Row className='pl-3'>
                                <img src={PhoneIcon} alt='Phone Icon' className='h-100 pt-1 mr-1'></img>
                                <p className='text-muted'><small>917-444-2230<br />
                                    917-520-0584</small></p>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <SubmitNotice
                show={submitNotice}
                onHide={() => setSubmitNotice(false)}
            />
        </Container >
    );
}

export default ContactUsSection;