import React from 'react';
import { connect } from 'react-redux';
import SchoolCarousel from './schoolCarousel';
import {
    activateSearch,
    deactivateSearch
} from '../actions/searchAction';

import SearchBar from './searchbar';

function HomeHook () {
    return (
        <div className='container-fluid h-100'>
            <div className='row' style={{height:"90%"}}>
                <div className="col">
                    <div className='row h-25'></div>
                    <div className='row justify-content-center'>
                        <h1 className='text-white d-none d-md-block display-1 text-break'>
                            <strong>Elementary to College</strong>
                        </h1>
                        <h1 className='text-white d-block d-md-none'>
                            <strong>Elementary to College</strong>
                        </h1>
                    </div>
                    <div className='row justify-content-center'>
                        <h1 className='text-white d-none d-md-block display-4'>
                            Smart Navigation
                        </h1>
                        <h3 className='text-white d-block d-md-none'>
                            Smart Navigation
                        </h3>
                    </div>
                    <div className='row d-none d-md-block mt-5'>
                        <SearchBar></SearchBar>
                    </div>
                </div>
            </div>
            <div className="row" style={{height:"10%"}}>
                <SchoolCarousel />
            </div>
        </div>
    );
}

function msp(state) {
    return {
        searchActive: state.search.searchActive
    };
}

export default connect(msp, {
    activateSearch,
    deactivateSearch
})(HomeHook);