import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function SubmitNotice(props) {

  return (
    <Modal
      {...props}
      dialogClassName="SubmitNotice"
      aria-labelledby="submit-notice"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="submit-notice">
          Submitted!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
            Thank you for your inquiry. We will get back to you as soon as possible.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SubmitNotice;