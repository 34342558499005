import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup'
import ElementaryModal from '../modals/elementary';
import JhsModal from '../modals/jhs';
import HsModal from '../modals/hs';

function SearchMenu() {
    const [elementaryShow, setElementaryShow] = React.useState(false);
    const [jhsShow, setJhsShow] = React.useState(false);
    const [hsShow, setHsShow] = React.useState(false);

    return (
        <div className='w-100'>
            <ListGroup>
                <ListGroup.Item action variant="light" onClick={() => setElementaryShow(true)}>
                    ELEMENTARY EDUCATION
                </ListGroup.Item>
                <ListGroup.Item action variant="light" onClick={() => setJhsShow(true)}>
                    JUNIOR HIGH SCHOOL EDUCATION
                </ListGroup.Item>
                <ListGroup.Item action variant="light" onClick={() => setHsShow(true)}>
                    HIGH SCHOOL EDUCATION
                </ListGroup.Item>
            </ListGroup>

            <ElementaryModal
                show={elementaryShow}
                onHide={() => setElementaryShow(false)}
            />
            <JhsModal
                show={jhsShow}
                onHide={() => setJhsShow(false)}
            />
            <HsModal
                show={hsShow}
                onHide={() => setHsShow(false)}
            />
        </div>
    );
}

export default SearchMenu;