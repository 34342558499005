import React from 'react';
import './App.css';
import CentralColumn from './components/container/centralColumn';
import Navbar from './components/navbar';
import AboutPage from './components/about';
import RequestService from './components/requestService';
import OnlinePlatformSection from './components/onlinePlatformSection';
import WhyMerica from './components/whyMerica';
import ContactFooter from './components/contactFooter';
import HomeHook from './components/homeHook';
import ContactUs from './components/contactUs';

function App(props) {
  return (
    <div className="App">
      <Navbar />

      <div className='Main main_body-section'>
        <HomeHook />
      </div>

      <CentralColumn className='main_body-section main_body-section__one border-top'>
        <AboutPage />
      </CentralColumn>

      <CentralColumn className='main_body-section main_body-section__two'>
        <RequestService />
      </CentralColumn>

      <CentralColumn className='main_body-section main_body-section__one'>
        <WhyMerica />
      </CentralColumn>

      <CentralColumn className='main_body-section diagonal-design'>
        <OnlinePlatformSection />
      </CentralColumn>

      <CentralColumn className='main_body-section main_body-section__one'>
        <ContactUs />
      </CentralColumn>

      <ContactFooter />
    </div>
  );
}

export default (App);
