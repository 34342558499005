function activateSearch () {
    return {type: 'ACTIVATE SEARCH'};
}

function deactivateSearch () {
    return {type: 'DEACTIVATE SEARCH'};
}

export {
    activateSearch,
    deactivateSearch
}