import React from 'react';
import Princeton from '../public_assets/university-logos/princeton.png';
import Harvard from '../public_assets/university-logos/harvard.png';
import Columbia from '../public_assets/university-logos/columbia.png';
import Chicago from '../public_assets/university-logos/chicago.png';
import Cornell from '../public_assets/university-logos/cornell.png';

function SchoolCarousel () {
  return (
    <div className="container-fluid d-flex justify-content-center" style={{opacity:"75%",fontSize:"1.25vw"}}>
      <div className="row school-carousel">
          <div className="col d-flex align-items-center justify-content-center">
            Admitted To:
          </div>
          <div className="col d-flex align-items-center justify-content-center">
            <a target="_blank" rel="noopener noreferrer" href="https://www.harvard.edu/">
              <img className="img-fluid" src={Harvard} alt="Harvard"/>
            </a>
          </div>
          <div className="col-3 d-flex align-items-center justify-content-center">
            <a target="_blank" rel="noopener noreferrer" href="https://www.princeton.edu/">
              <img className="img-fluid" src={Princeton} alt="Princeton"/>
            </a>
          </div>
          <div className="col d-flex align-items-center justify-content-center">
            <a target="_blank" rel="noopener noreferrer" href="https://www.columbia.edu/">
              <img className="img-fluid" src={Columbia} alt="Columbia"/>
            </a>
          </div>
          <div className="col-3 d-flex align-items-center justify-content-center">
            <a target="_blank" rel="noopener noreferrer" href="https://www.uchicago.edu/">
              <img className="img-fluid" src={Chicago} alt="Chicago"/>
            </a>
          </div>
          <div className="col d-flex align-items-center justify-content-center">
            <a target="_blank" rel="noopener noreferrer" href="https://www.cornell.edu/">
              <img className="img-fluid" src={Cornell} alt="Cornell"/>
            </a>
          </div>
      </div>
    </div>
  );
}

export default SchoolCarousel;