import React from 'react';
import { Container, Row } from 'react-bootstrap';

function AboutPage() {
    return (
        <Container>
            <a className="to-about" name="About" href="/#" style={{height:"0"}}>to-about</a>
            <a className="to-about-with-menu" name="About-Menu" href="/#" style={{height:"0"}}>to-about-with-menu</a>
            <Row className='d-block align-content-center text-center'>
                <div className='mb-4 text-center'>
                    <h2 style={{fontWeight:"bold"}}>About Us</h2>
                </div>
                <div className="mx-4" style={{fontSize:".95rem"}}>
                    <p>
                        <strong><font color="#16adef">Merica Consulting</font></strong> is a full-service educational consulting firm located in New York. For over ten years, our trained specialists have consistently helped students improve their academic performance with strategic guidance and gain acceptance into elite American universities.
                    </p>
                    <p>
                        <strong><font color="#16adef">College application preparation</font></strong> doesn’t begin in the junior year, but in fact, far before high school. A structured academic management at an early stage provides stable building blocks to successful further education. It also inspires valuable aspects such as healthy habits, passion, and mindsets.
                    </p>
                    <p>
                        <strong><font color="#16adef">Our mission</font></strong> is to help students identify their dreams, then teach how the dream that seems so far, can be reached. We administer the MBTI and major identification sessions to assist students in identifying their goals and passion. Combined with reliable management of GPA, school curriculum, standardized tests, and extracurricular activities, Merica provides an innovative, tailored prep for each student to exceeding what they believed to be their limits.
                    </p>
                </div>
            </Row>
        </Container>
    );
}

export default AboutPage;