import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import StudentCenteredPhoto from '../public_assets/why-merica/student-centered.jpg';
import ResultOrientedPhoto from '../public_assets/why-merica/result-oriented.png';
import IndividualizedPhoto from '../public_assets/why-merica/individualized.png';
import InnovativePhoto from '../public_assets/why-merica/innovative.png';
import ContactUsModal from '../modals/contact-us';

function WhyMerica(props) {

    const [contactShow, setContactShow] = React.useState(false);

    return (
        <div>
            <Container className="why-merica-container text-center">
                <Row className='justify-content-center my-2'>
                    <h2 style={{ fontWeight: "bold" }}>Why Merica?</h2>
                </Row>
                <Row className='mb-4'>
                    <Col>
                        <img src={StudentCenteredPhoto} alt="Student Centered"></img>
                        <h2>
                            Student Centered
                        </h2>
                        <div className='w-100 mx-auto text-center'>
                            Merica administers strategic planning, assessing both where you are and where you're going. You share your vision and values, together we plan to reach them.
                        </div>
                    </Col>
                    <Col>
                        <img src={ResultOrientedPhoto} alt="Result Oriented"></img>
                        <h2>
                            Result Oriented
                        </h2>
                        <div className='w-100 mx-auto text-center'>
                            Merica administers strategic planning, assessing both where you are and where you're going. You share your vision and values, together we plan to reach them.
                        </div>
                    </Col>
                </Row>
                <Row className='mb-4'>
                    <Col>
                        <img src={IndividualizedPhoto} alt="Individualized Support"></img>
                        <h2>
                            Individualized Support
                        </h2>
                        <div className='w-100 mx-auto text-center'>
                            Our services are tailored for each student, empowering students to develop executive functioning skills and grow confidence in their abilities.
                        </div>
                    </Col>
                    <Col>
                        <img src={InnovativePhoto} alt="Innovative"></img>
                        <h2>
                            Innovative
                        </h2>
                        <div className='w-100 mx-auto text-center'>
                            We start at a fresh page for each student’s story, allowing an innovative approach that produces groundbreaking results.
                        </div>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <div className="mx-auto">
                        <Button size="lg" variant="primary" onClick={(e) => {
                            setContactShow(true);
                            e.target.blur();
                        }}>
                            FREE MOCK TEST
                        </Button>
                    </div>
                </Row>
            </Container>

            <ContactUsModal
                show={contactShow}
                onHide={() => setContactShow(false)}
            />
        </div>
    )
}

export default WhyMerica;